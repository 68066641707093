@tailwind base;
@tailwind components;
@tailwind utilities;

/* scrollbar */
@import "simplebar-react/dist/simplebar.min.css";
/* @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Dancing+Script:wght@400..700&family=Lato:ital,wght@0,400;0,700;0,900;1,300&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

*,
body,
html {
  /* font-family: "Poppins", sans-serif; */
  font-family: "Rubik", sans-serif;
  -webkit-text-decoration: none;
  text-decoration: none;
}

a,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Poppins", sans-serif; */
  font-family: "Rubik", sans-serif;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

/* :root{
  background-color: #f3e8ff;
} */

.cat_swiper {
  width: 100%;
  height: 100%;
   background-color: #ffff !important;
 
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent !important;
  padding:  12px 0px !important;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media (min-width:480px) {
  .cat_swiper .swiper-wrapper{
    width: 100%;
    height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
  }
  
}
